// eslint-disable-next-line no-unused-vars
import './../views/_menupage.css';

import { JaaSMeeting } from '@jitsi/react-sdk';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import logo from './../images/MEDIMEET.png';

import ShowMenu from './../images/new/Icons_ShowMenu.svg'
import LogoWhiteboard from './../images/new/Icons_Whiteboard.svg'
import LogoWhiteboardClose from './../images/new/Icons_Whiteboard-close.svg'
import LogoMicrophone from './../images/new/Icons_Microphone-enable.svg'
import LogoWebcam from './../images/new/Icons_Camera-enable.svg'
import CloseMicrophone from './../images/new/Icons_Microphone-disable.svg'
import CloseWebcam from './../images/new/Icons_Camera-diable.svg'
import SnapShowIcon from './../images/Icons_Capture.svg'
import SnapShowDisableIcon from './../images/Icons_Capture-disable.svg'

let urlSafeWhiteBoard;

document.documentElement.addEventListener(
  'touchstart',
  function(event) {
    if(event.touches.length > 1) {
      event.preventDefault()
    }
  },
  {
    passive: false
  }
)

// import { Client } from './../client';
function WhiteBoardToggle() {
  let { id } = useParams();

  // console.log(id.toLowerCase());
  // urlSafeWhiteBoard = 'https://mmt-wb-test.azurewebsites.net/boards/' + id;
  urlSafeWhiteBoard = process.env.REACT_APP_MMT_URL + id.toLowerCase();

  return (
    <div className="whiteboard-container">
      <iframe
        src={urlSafeWhiteBoard}
        title="Whiteboard"
        className="whiteboard-iframe"
        id="whiteboardIframe"
      ></iframe>
    </div>
  )
}

// function snapShot() {
//   window.top.postMessage('(SnapShot)', '*');
// }

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function snapShot() {
  window.chrome.webview.postMessage('MRSnapShot');
}

const MeetingRoom = () => {
  let showWhiteboardToggle = true;
  const [showToolbar, setShowToolbar] = useState(false);
  const [openToolbar, setOpenToolbar] = useState(false);
  const [toggleWBO, setToggleWBO] = useState(false);
  const [toggleSnapshot, setToggleSnapshot] = useState(false);
  const [toggleAudio, setToggleAudio] = useState(false);
  const [toggleVideo, setToggleVideo] = useState(false);
  const [role, setRole] = useState(null);
  const [username, setUsername] = useState(null);

  const [videoDeviceNumber, setVideoDeviceNumber] = useState(0);
  const [audioDeviceNumber, setAudioDeviceNumber] = useState(0);
  const [isFollowme, setIfFollowme] = useState(false);

  const [showWhiteboardClose, setShowWhiteboardClose] = useState(false);

  useEffect(() => {
    setToggleWBO(toggleWBO);
  }, [toggleWBO]);

  useEffect(() => {
    setToggleAudio(toggleAudio);
  }, [toggleAudio]);

  useEffect(() => {
    setToggleVideo(toggleVideo);
  }, [toggleVideo]);

  useEffect(() => {
    setToggleSnapshot(toggleSnapshot);
  }, [toggleSnapshot]);

  useEffect(() => {
    setOpenToolbar(openToolbar);
  }, [openToolbar]);

  useEffect(() => {
    setIfFollowme(isFollowme);
  }, [isFollowme]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    let roles = params.get('roles');
    let name = params.get('username');
    let isFollowmes = params.get('isFollowme');
    setRole(roles);
    setUsername(name)
    setIfFollowme(isFollowmes);
  }, [role, isFollowme]);

  const { id } = useParams();

  const cookies = new Cookies();

  const refToolbar = useRef(null);
  const ref = useRef(null);
  const refIMG = useRef(null);
  const refAudioBtn = useRef(null);
  const refSnapShot = useRef(false);
  const refMobile = useRef(null);
  const refVideoBtn = useRef(null);
  const closeWBBtn = useRef(null);

  const openWhiteboard = useRef(false);
  const openAudio = useRef(false);
  const openWebcam = useRef(false);
  // const openSnapshot = useRef(false);


  let isIOS = iOS();

  const navigate = useNavigate();

  // Save list from URL Params to Cookies
  const paramsRoomName = () => {
    let roomList = cookies.get('roomList');

    if (roomList === undefined) {
      if (roomList !== '') {
        roomList = [roomList];
        cookies.set('roomList', roomList, { path: '/', maxAge: 31536000 });
      }
    } else {
      if (roomList.includes(id) === false) {
        if (id !== '') {
          cookies.set('roomList', roomList, { path: '/', maxAge: 31536000 });
          roomList.push(id);
          cookies.set('roomList', roomList, { path: '/', maxAge: 31536000 });
        }
      }
    }
  };

  paramsRoomName();
  let BGImg = '';
  // let api = {};
  const JitsiAPI = (JitsiMeetAPI) => {
    const toolbarBtn = refToolbar.current;
    const elementToggle = ref.current;
    const imgMessage = refIMG.current;
    const audioBtn = refAudioBtn.current;
    const videoBtn = refVideoBtn.current;
    const snapShotBtn = refSnapShot.current;
    const mobileToolBtn = refMobile.current;
    const closeWhiteboard = closeWBBtn.current;

    // window.top.postMessage('(Send Image)', '*');
    window.onmessage = function (e) {

      if ((e.data.toString().includes('(Meeting Image:')) && (e.data.toString().includes(')'))) {
        BGImg = e.data.substring(
          e.data.indexOf(":") + 1,
          e.data.lastIndexOf(")")
        );

        changeImage(BGImg);
        JitsiMeetAPI.executeCommand('sendChatMessage', '(BG Image:' + BGImg + ')', '', false);
      }
    }

    const listenToChangeImage = () => {
      if (BGImg !== '') {
        changeImage(BGImg);
        JitsiMeetAPI.executeCommand('sendChatMessage', '(BG Image:' + BGImg + ')', '', false);
      }
    }

    const chatSnapShot = () => {
      snapShot();
    }


    // Moderator Test
    // JitsiMeetAPI.executeCommand('muteEveryone', 'video');


    // 偵聽 Chat Message / BroadCast to All Participant
    JitsiMeetAPI.addEventListener('incomingMessage', function (res) {
      const msg = res.message;

      if (res.message === '(Start Whiteboard)') {
        openWhiteboard.current = true
        setToggleWBO(true);
      }
      if (res.message === '(Stop Whiteboard)') {
        openWhiteboard.current = false
        setToggleWBO(false);
      }

      if (res.message === '(Stop Whiteboard)') {
        openWhiteboard.current = false
        setToggleWBO(false);
      }

      if (res.message === '(SnapShot Disable)') {
        refSnapShot.current = false;
        setToggleSnapshot(false);
      }

      if (res.message === '(SnapShot Enable)') {
        refSnapShot.current = true;
        setToggleSnapshot(true);
        chatSnapShot();
      }

      if ((msg.toString().includes('(BG Image:')) && (msg.toString().includes(')'))) {
        var newURL = msg.substring(
          msg.indexOf(":") + 1,
          msg.lastIndexOf(")")
        );

        BGImg = newURL;

        setTimeout(function () {
          changeImage(newURL);
          clearTimeout();
        }, 500);
      }
    });

    // 取得 Audio Video Device
    // # audioInput 
    // # videoInput
    JitsiMeetAPI.getAvailableDevices().then(devices => {
      setAudioDeviceNumber(devices.audioInput.length);
      setVideoDeviceNumber(devices.videoInput.length);

      // JitsiMeetAPI.executeCommand('setVideoQuality', 1080);
      // console.log("setVideoQuality");
    });

    JitsiMeetAPI.addEventListener('audioMuteStatusChanged', function (event) {
      if (event.muted === true) {
        setToggleAudio(false);
        openAudio.current = false;
      }

      if (event.muted === false) {
        setToggleAudio(true);
        openAudio.current = true;
      }
    });

    JitsiMeetAPI.addEventListener('videoMuteStatusChanged', function (event) {
      if (event.muted === true) {
        setToggleVideo(false);
        openWebcam.current = false;
      }

      if (event.muted === false) {
        setToggleVideo(true);
        openWebcam.current = true;
      }
    });

    // participantJoined
    JitsiMeetAPI.addListener('participantJoined', listenToChangeImage);

    // Local User Join Meeting
    JitsiMeetAPI.addListener('videoConferenceJoined', function (event) {
      if(isFollowme === 'true') {
        JitsiMeetAPI.executeCommand('setFollowMe', { value: true });
        JitsiMeetAPI.executeCommand('pinParticipant', event.id );
      }

      JitsiMeetAPI.executeCommand('setTileView', false);
      setShowToolbar(true);

      toolbarBtn.addEventListener('click', function () {
        setOpenToolbar(openToolbar => !openToolbar);
      });


      // 偵聽 WhiteboardToggle 按鈕
      elementToggle.addEventListener('click', function () {
        setOpenToolbar(false);
        // openWhiteboard.current = !openWhiteboard.current;
        if (openWhiteboard.current === false) {
          openWhiteboard.current = true;
          JitsiMeetAPI.executeCommand('sendChatMessage', '(Start Whiteboard)', '', false);
          setToggleWBO(true);

          return;
        }
        if (openWhiteboard.current === true) {

          if(role === "Moderator") {
            setShowWhiteboardClose(true);
          } else {
            hideWhiteboard();
          }
        }
      });

      mobileToolBtn.addEventListener('click', function () {
        setOpenToolbar(false);
        // openWhiteboard.current = !openWhiteboard.current;
        if (openWhiteboard.current === false) {
          openWhiteboard.current = true;
          JitsiMeetAPI.executeCommand('sendChatMessage', '(Start Whiteboard)', '', false);
          setToggleWBO(true);

          return;
        }
        if (openWhiteboard.current === true) {

          if(role === "Moderator") {
            setShowWhiteboardClose(true);
          } else {
            hideWhiteboard();
          }
        }
      });
      
      // 强制所有人関白板 Moderator Only
      closeWhiteboard.addEventListener('click', function(){
        let wFrame = document.getElementById('whiteboardIframe');
        wFrame.contentWindow.postMessage('clearWhiteboard', urlSafeWhiteBoard);

        setTimeout(() => {
          JitsiMeetAPI.executeCommand('sendChatMessage', '(Stop Whiteboard)', '', false);
          setToggleWBO(false);
          setShowWhiteboardClose(false);
          openWhiteboard.current = false;
        }, 100);

      });

      // Listen Audio Btn and Video Button
      audioBtn.addEventListener('click', function () {
        openAudio.current = !openAudio.current;
        if (openAudio.current === true) {
          setToggleAudio(false);
        }
        if (openAudio.current === false) {
          setToggleAudio(true);
        }
        JitsiMeetAPI.executeCommand('toggleAudio');
      });

      // 開關視訊 按鈕 Function
      videoBtn.addEventListener('click', function () {
        openWebcam.current = !openWebcam.current;
        if (openWebcam.current === true) {
          setToggleVideo(false);
        }
        if (openWebcam.current === false) {
          setToggleVideo(true);
        }
        JitsiMeetAPI.executeCommand('toggleVideo');
      });

      // 截圖按鈕 Function
      snapShotBtn.addEventListener('click', function() {
        refSnapShot.current = !refSnapShot.current;
        if(refSnapShot.current === true) {
          setToggleSnapshot(false);
          JitsiMeetAPI.executeCommand('sendChatMessage', '(SnapShot Disable)', '', false);
          JitsiMeetAPI.executeCommand('sendChatMessage', '(BG Image:)', '', false); //清掉所有人的背景圖
          changeImage(''); //清掉自己的圖
        }
        if(refSnapShot.current === false) {
          setToggleSnapshot(true);
          JitsiMeetAPI.executeCommand('sendChatMessage', '(SnapShot Enable)', '', false);
          chatSnapShot();
        }

      })

      imgMessage.addEventListener('click', function () {
        changeImage(BGImg);
        JitsiMeetAPI.executeCommand('sendChatMessage', '(BG Image:' + BGImg + ')', '', false);
      });
    });

    JitsiMeetAPI.addListener('videoConferenceLeft', function () {
      navigate('/');
    });

    // Local User Leave Meeting
    JitsiMeetAPI.addListener('readyToClose', function () {
      JitsiMeetAPI.removeListener('videoConferenceJoined', function () { });
      JitsiMeetAPI.removeListener('videoConferenceLeft', function () { });
      JitsiMeetAPI.removeListener('incomingMessage', function () { });
      JitsiMeetAPI.removeListener('participantJoined', function () { });
    });
  }

  const changeImage = (url) => {
    let wFrame = document.getElementById('whiteboardIframe');
    wFrame.contentWindow.postMessage(url, urlSafeWhiteBoard);

  }

  const hideWhiteboard = () => {
    openWhiteboard.current = false;
    setToggleWBO(false);
    setShowWhiteboardClose(false);
  }

  const closeModal = () => {
    setShowWhiteboardClose(false);
  }


  const appId = 'vpaas-magic-cookie-31ab6f7e6a0a4a288a0308f2fb6cc2dc';
  // const JWT = 'eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtMzFhYjZmN2U2YTBhNGEyODhhMDMwOGYyZmI2Y2MyZGMvOGMyN2I1IiwidHlwIjoiSldUIiwiYWxnIjoiUlMyNTYifQ.eyJhdWQiOiJqaXRzaSIsImlzcyI6ImNoYXQiLCJpYXQiOjE2NzI4MTY4NzIsImV4cCI6MTc3MjgyNDA3MiwibmJmIjoxNjcyODE2ODY3LCJzdWIiOiJ2cGFhcy1tYWdpYy1jb29raWUtMzFhYjZmN2U2YTBhNGEyODhhMDMwOGYyZmI2Y2MyZGMiLCJjb250ZXh0Ijp7ImZlYXR1cmVzIjp7ImxpdmVzdHJlYW1pbmciOnRydWUsIm91dGJvdW5kLWNhbGwiOnRydWUsInNpcC1vdXRib3VuZC1jYWxsIjpmYWxzZSwidHJhbnNjcmlwdGlvbiI6dHJ1ZSwicmVjb3JkaW5nIjp0cnVlfSwidXNlciI6eyJoaWRkZW4tZnJvbS1yZWNvcmRlciI6ZmFsc2UsIm1vZGVyYXRvciI6dHJ1ZSwibmFtZSI6IiIsImlkIjoiIiwiYXZhdGFyIjoiIiwiZW1haWwiOiIifX0sInJvb20iOiIqIn0.C0xobcyVplnBa4UGtFoI_kRBRgyuPDeCZehvB5G1qOIRY7Ga3-w96sEr48ymsDsTkDux7UgQ54fKFfsIUNIGdGwSZN3uFI4Swaij4smIYTFaUWoSCWERpUGYFRKr4BGXpdD631Mb6zjDkBXlSm5P_h_HzWd_4dE-Cr_7b1QcMIF5G_sP5vFFhsb9dXCJqGWH5sKXxhqpIs9Y2WyTXJ12ycjsDcx0VpRokp27LGstMLP5TNwV_GTf6wSw7V_EOPO-Ntq4Do9fHylIvhsuo2xF9191o2ziJLvUFos6gyDSmvJU7HLyWubonlrctzCZcHIXPu5evcJ2gi_NvbQvtZ6_OA'
  const JWT = 'eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtMzFhYjZmN2U2YTBhNGEyODhhMDMwOGYyZmI2Y2MyZGMvYjNhYjc3IiwiYWxnIjoiUlMyNTYiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiJqaXRzaSIsImNvbnRleHQiOnsiZmVhdHVyZXMiOnsibGl2ZXN0cmVhbWluZyI6InRydWUiLCJ0cmFuc2NyaXB0aW9uIjoidHJ1ZSIsInJlY29yZGluZyI6InRydWUiLCJzaXAtaW5ib3VuZC1jYWxsIjoidHJ1ZSIsInNpcC1vdXRib3VuZC1jYWxsIjoidHJ1ZSIsImluYm91bmQtY2FsbCI6InRydWUiLCJvdXRib3VuZC1jYWxsIjoidHJ1ZSJ9LCJ1c2VyIjp7ImhpZGRlbi1mcm9tLXJlY29yZGVyIjpmYWxzZSwibW9kZXJhdG9yIjp0cnVlLCJuYW1lIjoiIiwiaWQiOiIiLCJhdmF0YXIiOiIiLCJlbWFpbCI6IiJ9fSwiZXhwIjo5OTk5OTk5OTk5LCJpc3MiOiJjaGF0IiwibmJmIjoxNTk2MTk3NjUyLCJyb29tIjoiKiIsInN1YiI6InZwYWFzLW1hZ2ljLWNvb2tpZS0zMWFiNmY3ZTZhMGE0YTI4OGEwMzA4ZjJmYjZjYzJkYyJ9.w4ldxXyYKaIIbx9FNxxjnHVdBr9J8Q3TZrpW9MbYJ5oba3M60J1h94uyBTN2J0DlUPGq6JWA-uzpj0ZmfRJGLu8XPALy5bTewThlvkuktnI8pM5GCTpDy2L083arRIqG9DUEQtKsxa_WATNIaHuDJN6db14kwc0NUKvMJuaKCkMNoLpQkiGqKLxaJsK9g3gxgjiYxKY8TllJryZVX2dfdp_CYNGHhciD3567p6R2H9KeKG-CqzCCU2opQ_G56nDdWyalB7wZ9OzxmjGawQZAfF-0tml1skqgMmr6uYJzMvo_-V-_m4AZOAlYtfbcNlbd7RCNu53QIES7y4I7T89Flw'
  const params = useParams();

  const appConfig = {
    startWithAudioMuted: false,
    startWithVideoMuted: false,
    disableModeratorIndicator: false,
    // startScreenSharing: true,
    enableEmailInStats: false,
    disabledNotifications: true,
    participantsPane: true,
    prejoinPageEnabled: true,
    brandingRoomAlias: params.id,
    resolution: 1080,
    constraints: {
        video: {
            height: {
                ideal: 1080,
                max: 1080,
                min: 720,
            }
        }
    },
    videoQuality: {
      disabledCodec: 'H264',
      preferredCodec: 'VP8',
      maxBitratesVideo: {
          H264: {
              low: 1200000,
              standard: 2400000,
              high: 6500000
          },
          VP8: {
              low: 1200000,
              standard: 2400000,
              high: 6500000
          },
          VP9: {
              low: 1200000,
              standard: 2400000,
              high: 6500000
          }
      },
      minHeightForQualityLvl: {
          360: 'low',
          720: 'standard',
          1080: 'high'
      },
      resizeDesktopForPresenter: false
    },
    disableSimulcast: false,
  };

  const interfaceConfig = {
    LANG_DETECTION: true,
    // lang: "es",
    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
    HIDE_INVITE_MORE_HEADER: true,
    MOBILE_APP_PROMO: false,
    SHOW_CHROME_EXTENSION_BANNER: false,
    ENABLE_AUTH: true,
    ENABLE_PREJOIN_PAGE: true,
    AUTH_TYPE: "jwt",
    TOOLBAR_BUTTONS: [
      'camera',
      // 'chat',
      'closedcaptions',
      'desktop',
      'download',
      'embedmeeting',
      'etherpad',
      'feedback',
      'filmstrip',
      'fullscreen',
      'hangup',
      'help',
      'highlight',
      'invite',
      'linktosalesforce',
      'livestreaming',
      'microphone',
      'noisesuppression',
      'participants-pane',
      'profile',
      'raisehand',
      'recording',
      'security',
      'select-background',
      'settings',
      'shareaudio',
      'sharedvideo',
      'shortcuts',
      'stats',
      'tileview',
      'toggle-camera',
      'videoquality',
      'whiteboard',
    ]
  };

  return (
    <div>
      {/* 若偵測是Mobile Device */}
      {isMobile ?
        <div className="app-detect">
          <header>
            <img src={logo} alt={"logo"}></img>
          </header>
          <div className="device-container">
            {/* 您需要在手機上安裝MEDIMEET 行動應用程式才能加入這場會議<br></br>
            如果您已經有App了： */}
            <p>You need the MEDIMEET mobile app to join this meeting on your phone.</p>
            <p>If you already have the app</p>

            <a href={'medimeet://mmt-web-test.azurewebsites.net/' + id} target={'_top'}>
              <span>Join this meeting using the app</span>
            </a>

            <p>If you don't have the app yet.</p>

            {isIOS ?
              <a href="https://apps.apple.com/app/medimeet/id1537073383" target={'_top'}>
                <span>Download the app</span>
              </a>
              :
              <a href="https://play.google.com/store/apps/details?id=tw.com.twinbeans.metandroid" target={'_top'}>
                <span>Download the app</span>
              </a>
            }
          </div>
        </div>
        :
        <div className="container-iframe">
          {
            showWhiteboardToggle === true ?
              <div>
                <div className="toolbar-mask"></div>
                <div className="mobile-toolbox">
                  <button className={"toolbar-btn " + (toggleWBO ? 'active' : '')} ref={ refMobile } >
                    {
                      toggleWBO === false ?
                        <img src={LogoWhiteboard} alt={"whiteboard"} />
                        :
                        <img src={LogoWhiteboardClose} alt={"whiteboardClose"} />
                    }
                  </button>
                </div>

                <div style={{ display: showToolbar ? '' : 'none' }} className={"toolbar-container pc-toolbox " + (openToolbar ? 'active' : '')}>
                  <img ref={refToolbar} src={ShowMenu} alt={"ShowMenu"} className={"show-menu-icon " + (openToolbar ? 'active' : '')} />

                  <button className={"toolbar-btn " + (toggleWBO ? 'active' : '')} ref={ ref } >
                    {
                      toggleWBO === false ?
                        <img src={LogoWhiteboard} alt={"whiteboard"} />
                        :
                        <img src={LogoWhiteboardClose} alt={"whiteboardClose"} />
                    }
                  </button>
                  <button className={"toolbar-btn " + 
                  (toggleWBO === false ? 'hide ' : '')} ref={ refSnapShot }>
                    {
                      toggleSnapshot === true ?
                        <img src={ SnapShowDisableIcon } alt={" SnapShotDisable "}></img>
                        :
                        <img src={ SnapShowIcon } alt={" SnapShot "}></img>
                    }
                  </button>
                  <button className={"toolbar-btn " + 
                  (toggleWBO === false ? 'hide ' : '') + 
                  (audioDeviceNumber > 0 ? '' : 'disabled')} ref={refAudioBtn}>
                    {
                      toggleAudio === true && audioDeviceNumber > 0 ?
                        <img src={LogoMicrophone} alt={" AudioButton "}></img>
                        :
                        <img src={CloseMicrophone} alt={" AudioButton "}></img>
                    }
                  </button>
                  <button className={"toolbar-btn " + 
                  (toggleWBO === false ? 'hide ' : '') +
                  (videoDeviceNumber > 0 ? '' : 'disabled')} ref={refVideoBtn}>
                    {
                      toggleVideo === true && videoDeviceNumber > 0 ?
                        <img src={LogoWebcam} alt={" WebcamButton "}></img>
                        :
                        <img src={CloseWebcam} alt={" WebcamButton "}></img>
                    }
                  </button>
                </div>
              </div>
              :
              <></>

          }
          {
            role === 'Moderator' ?
              // Moderator
              <JaaSMeeting
                appId={appId}
                jwt={JWT}
                roomName={id}
                configOverwrite={appConfig}
                // useStaging = { true }
                userInfo={{ 
                  displayName: username 
                }}
                interfaceConfig={interfaceConfig}
                interfaceConfigOverwrite={interfaceConfig}
                onApiReady={ JitsiAPI }
              />

              :
              // Non Moderator
              <JaaSMeeting
                appId={appId}
                roomName={id}
                configOverwrite={appConfig}
                // useStaging = { true }
                userInfo={{ 
                  displayName: username 
                }}
                interfaceConfig={interfaceConfig}
                interfaceConfigOverwrite={interfaceConfig}
                onApiReady={ JitsiAPI }
              />
          }

          {
            toggleWBO ?
              <WhiteBoardToggle />
              :
              <></>
          }

        </div>
      }
      <div className="modal-mask" onClick={ closeModal } style={{ display: showWhiteboardClose ===  true ? '' : 'none' }}>
        <div className="modal-show-whiteboard">
          <button onClick={ hideWhiteboard }>Hide Whiteboard</button>
          <button ref={ closeWBBtn }>End Whiteboard</button>
        </div>
      </div>
    </div>
  );

}

export default MeetingRoom;


